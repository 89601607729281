import { GenericService } from './generic.service';
import { Episode } from '@/models';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import apiClient from './client/apiClient';

export class EpisodesService extends GenericService<Episode> {
  pathway: string;

  constructor(pathway: string) {
    super();
    this.pathway = pathway;
    this.endpoint = `v1/worklists/${pathway}/episodes`;
  }

  async getEncounters(params: AxiosRequestConfig = {}): Promise<AxiosResponse> {
    return await apiClient.get(`v1/worklists/${this.pathway}`, params);
  }

  async getFilters(params: AxiosRequestConfig = {}): Promise<AxiosResponse> {
    return await apiClient.get(`v1/worklists/${this.pathway}/filters`, params);
  }
}
